<template>
    <div class="d-inline-flex align-items-baseline">
        <img v-show="left" class="button-icon" :src="image_url">
        <h6>{{buttonLabel}}</h6> 
        <img v-show="!left" class="button-icon" :src="image_url">

        <p>
            <en> English text </en>
            <de> German text </de>
        </p>
    </div>
</template>

<script>
    export default {
        props: {
            buttonLabel: String,
            imageFileName: String, 
            left: Boolean,
        },

        computed: {
            image_url() {

                let base_url = "../assets/elements/"

                switch(this.buttonLabel.toLowerCase()) {
                    case "edit": 
                        return base_url + 'edit_icon_black.svg';
                    case "delete": 
                        return base_url + 'trash_icon_black.svg';
                    default:
                        return base_url + 'forward-arrow-black.svg';
                    
                }
            }
        }
    }
</script>

<style scoped>

h6 {
    font-family: "Roboto Regular", sans-serif;
    font-size: 1em;
    margin: 0 !important;
}

.button-icon{
    height: 0.7em;
    width: auto;
    padding: 0 0.3em
}
</style>