<template>
  <div class="d-flex h-100 no-margin" :class="{'flex-column': mobile}">
    
    <div class="d-flex" :class="mobile ? '' : 'h-100'">
      <Navbar :mobile="mobile" />
    </div>
  
    <div class="d-flex flex-grow-1">
      <ProjectDisplay
        v-if="loaded"
        v-on:next="increaseIndex"
        v-on:prev="decreaseIndex"
        :project="projects[projectIndex]"
        
      />
      <Loading v-if="!loaded"/>
    </div>
  </div>
</template>


<script>
import Navbar from "@/components/Navbar.vue";
// import MobileNavbar from "@/components/MobileNavbar.vue"
import ProjectDisplay from "@/components/ProjectDisplay.vue";
import Loading from "@/components/Loading.vue";
import {arrayops} from "../helpers"
// import axios from 'axios';
import { projectdb } from '../fire'

export const projects = []

export default {
  name: "Home",
  data() {
    return {
      loaded: false,
      projectIndex: 0,
      projects
    };
  },

  methods: {
    increaseIndex: function () {
      this.projectIndex = arrayops.next(this.projectIndex, this.projects.length);
      // this.projectIndex = (this.projectIndex + 1) % this.projects.length;
    },
    decreaseIndex: function () {
      this.projectIndex = arrayops.prev(this.projectIndex, this.projects.length);
      // this.projectIndex = (this.projects.length + this.projectIndex - 1) % this.projects.length;
    },
  },

  created: async function () {
    // Get the content from the db (all projects)
    var allprojects = []

    await projectdb.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const project = doc.data()
        project["project_url"] = "projects/" + doc.id
        console.log(project)
        allprojects.push(project)
      })
    })

    this.projects = allprojects
    this.loaded = true
    console.log(this.projects)
  },
  components: {
    Navbar,
    // MobileNavbar,
    ProjectDisplay,
    Loading,
  },

  computed: {
    mobile: function () {
      console.log("Mobile is " + this.$isMobile())
        return this.$isMobile()
    }
  }
};
</script>

<style scoped>

.project-container {
  padding: 20px;
}

.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}
</style>