
function next(currentpos, arraylength) {
    return (currentpos + 1) % arraylength;
}

function prev(currentpos, arraylength) {
    return (arraylength + currentpos - 1) % arraylength
}

export const arrayops = {
    next,
    prev
}