<template>
  <div style="height: 100vh">
    <router-view/>
  </div>
  
</template>

<script>


export default {
  name: 'App', 
  components: {
  }
}
</script>

<style>

#app {
  font-family: 'Yantramanav', sans-serif, 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

/* Set Global Font */
* {
    font-family: 'Yantramanav', sans-serif;
  }

</style>
