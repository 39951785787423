<template>
  <div>
    <h1>Project View</h1>
    <h5>All projects listed</h5>
  </div>
</template>

<script>
export default {
  name: "Projects",
  data() {
    return {
      project: {
        name: String, 
        description: String
      }
    };
  },
};
</script>