<template>
  <div class="d-flex container login-body justify-content-center h-100">
    <div class="col-md-6 h-100">
    
    <div class="title h-50">
        <h1>Login</h1>
    </div>

    <div class="form">
        <div class="errormessage d-flex justify-content-center" v-if="errormessage">
            {{ errormessage }}
        </div>
        <form @submit.prevent="pressed" @keyup.enter="pressed">
          <div class="login padded" >
              <input
              type="text"
              :class="{ emailerror : emailerror}"
              placeholder="email"
              onfocus="this.placeholder = ''"
              onblur="this.placeholder = 'email'"
              v-model="email"
              
              />
          </div>
          <div class="password padded">
              <input
              type="password"
              placeholder="password"
              onfocus="this.placeholder = ''"
              onblur="this.placeholder = 'password'"
              v-model="password"
              />
          </div>
        <div class="padded d-flex justify-content-center">
            <a v-on:click="pressed" class="login-button">Login</a>
        </div>
        </form>
        
    </div>
    
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
require("firebase/auth");

export default {
  data() {
    return {
      email: "",
      password: "",
      errormessage: "",
      emailerror: false,
    };
  },
  methods: {
    async pressed() {
      try {
        const val = await firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password);
        console.log(val);
        this.$router.replace({ name: "adminpanel" });
      } catch (err) {
        console.log(err);
        if (err.code == "auth/invalid-email") {
            this.errormessage = "Please enter a valid e-mail address"
            this.emailerror = true
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.login-body {
--error-color: #f1492b;
--light-gray: #A2A2A2;
}

.padded {
  padding-top: 2em;
}

.errormessage {
    color: var(--error-color);
}

.emailerror {
    color: var(--error-color) !important;
    border-bottom: 1px solid var(--error-color) !important;
}

.container {
  min-height: 100vh;
  overflow: hidden;
}

.login-button {
	background-color:transparent;
	display:inline-block;
	cursor:pointer;
	color: var(--light-gray);
	text-decoration:none;
    transition: all 0.3s ease;
    padding-top: 10vh;
}
.login-button:hover {
    color: #505050
}
.login-button:active {
	position:relative;
	top:1px;
}


h1 {
  font-family: "Playfair Display", serif;
  color: var(--light-gray);
  font-size: 4em;
  display: flex;
  text-align: center;
  justify-content: center;
  padding-top: 15vh;
}

.form {
    padding-top: 10vh;
}

.form input {
  font-family: "Roboto", sans-serif;
  color:var(--light-gray);
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--light-gray);
  transition: all 0.3s ease;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-bottom: 1px solid #808080;
}

input:valid {
  color: #808080;
}
</style>