import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'

import firebase from "firebase/app";
import "firebase/auth";

import store from "@/store/store.ts"


// Animation
import SequentialEntrance from 'vue-sequential-entrance'
import 'vue-sequential-entrance/vue-sequential-entrance.css'
Vue.use(SequentialEntrance);

// Simple mobile detection
import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

Vue.config.productionTip = false

let app;

firebase.auth().onAuthStateChanged(user => {
  console.log("Authenticated:" + user)
  if(!app) {
    app = new Vue({
              router,
              store,
              render: h => h(App)
              }).$mount('#app')
  }
})






