<template>
    <div class="container w-100 vh-100">

      <!-- Header Row -->
        <div class="d-flex flex-column justify-content-between align-items-end w-100 header h-25">
          
          <div class="d-flex justify-content-between align-items-center w-100 h-75">
            <h1>Projects</h1>
            <div class="custom-button" v-on:click="signOut">
              <div class="d-inline-flex">
                <h5>Logout</h5>
                <img
                  class="button-icon-signout"
                  src="../assets/elements/sign_out_icon.svg"
                />
              </div>
            </div>
          </div>

          <div class="d-flex custom-button align-items-end h-25" >
            <div class="d-flex d-inline-flex align-items-baseline">
              <img
                class="button-icon"
                :class="{ rotated45: isAdding }"
                src="../assets/elements/plus.svg"
              />
              <h5 v-on:click="addProject">
                {{ isAdding ? "Cancel" : "Add Project" }}
              </h5>
            </div>
          </div>
            <hr style="width: 100%; margin: 0; padding: 0" />
        </div>
        

      <!-- Project List -->
      <div class="d-flex justify-content-center w-100 h-75">
          
        <div class="d-flex project-list justify-content-center w-100 h-100">
          <ProjectList v-if="!isAdding" style="width: 100%"></ProjectList>
          <AddProject v-if="isAdding" v-on:saved="isAdding=false"></AddProject>
        </div>
      </div>
    </div>
</template>

<script>
import ProjectList from "@/components/ProjectList.vue";
import AddProject from "@/components/AddProject.vue";

import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      isAdding: false,
      isEdditing: false,
    };
  },
  methods: {
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace({ name: "login" });
        });
    },
    addProject() {
      this.isAdding = !this.isAdding;
    }
  },

  components: {
    ProjectList,
    AddProject,
  },
};
</script>

<style lang="scss" scoped>

.container {
  height: 100vh;
}

h1 {
  font-family: "Playfair Display", serif;
}

h5 {
    color: #000000;
  text-align: center;
  margin: 0;
  font-size: 1em;
}

.custom-button {
  opacity: 30%;
}



.custom-button:hover {
  opacity: 70%;
  cursor: pointer;
}

.button-icon {
  padding: 0 0.5em;
  height: 0.7em;
  width: auto;
  transition: transform 0.2s ease-out;
}

.rotated45 {
  transform: rotate(45deg);
}

.button-icon-signout {
  padding: 0 0.5em;
  height: 0.9em;
  width: auto;
}

// Animations
// Rotation
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

.rotate-enter-active {
  animation: rotate 0.2s;
}

.rotate-leave-active {
  animation: rotate 0.2s reverse;
}
</style>
