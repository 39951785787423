<template>
  <div class="project-container h-100 w-100">

    <div class="project-display h-100 w-100" :style="{ backgroundImage: 'url(' + project.headerimage_url + ')' }" >
      <div class="h-50">
        <div class="padded text-center">
          <img v-on:click="$emit('prev')"
            class="controlArrow"
            src="../assets/projectArrow.svg"
            alt="Previous"
          />
        </div>
      </div>
      <div class="h-50 bottom-gradient ">
        <div class="row padded h-100 align-items-end">
          <div class="col text-left project-date smallerFont">{{project.year}}</div>
            <div class="col text-center">
              <img v-on:click="$emit('next')"
              class="controlArrow rotateimg180"
              src="../assets/projectArrow.svg"
              alt="Next"
            />
            </div>
          <div class="col text-right project-name">
            <router-link class="navlink smallerFont" :to="project.project_url">{{project.title}}</router-link>
              <img
                class="controlArrow-sm rotateimg90"
                src="../assets/projectArrow.svg"
              />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>







<script>

import {Project} from "../models/project.ts"

export default {
  name: "ProjectDisplay",

  props: {
    project: Project,
  },

};
</script>








<style scoped>
.project-container {
  margin: 0 !important;
}

.project-display {
  margin: 0 !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.controlArrow {
  width: 25px;
  box-shadow: 10px;
}
.controlArrow:hover {
  cursor: pointer;
}

.controlArrow-sm {
  width: 10px;
  box-shadow: 10px;
  margin-left:  5px;
}

.rotateimg180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}


.rotateimg90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.padded {
  padding: 20px;
}

.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.7)
  );
}

.project-date {
  color: #ffffff;
  opacity: 80%;
}

.navlink {
  color: #ffffff;
}

.smallerFont {
  font-size: 0.9em;
}
</style>

