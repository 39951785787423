<template>
  <div class="d-flex w-100 m-0">
    <b-list-group class="list-group-flush w-100">
      <b-list-group-item class="list-item" v-for="project in projects" :key="project.title">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex flex-column">
            <h3>{{ project.title }}</h3>
            <h5>{{ project.location }}</h5>
          </div>
            <div class="d-flex align-items-center">
              <div v-on:click="editProject"><Button class="list-button" buttonLabel="Edit" :left="false"></Button></div>
              <div   v-on:click="deleteProject"> <Button class="list-button" buttonLabel="Delete" :left="false"></Button> </div>
            </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>


<script>
import { projectdb } from "../fire";
import Button from '../components/Button'

export default {
  data() {
    return {
      projects: [],
      loaded: false,
    };
  },

  created: async function () {
    var allprojects = [];

    await projectdb.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const project = doc.data();
        project["project_url"] = "projects/" + doc.id;
        allprojects.push(project);
      });
    });

    this.projects = allprojects;
    this.loaded = true;
    console.log(this.projects);
  },

  methods: {
    editProject() {
      console.log("editing")
    }, 
    deleteProject() {
      const confirmation = confirm("Do you want to save changes?")
      if( confirmation == true) {
          console.log("Project Deleted")
      }
    }

  },

  components: {
    Button
  }
};
</script>

<style lang="scss" scoped>

.no-margin {
    margin: 0px!important
}

.no-padding {
    padding: 0px!important
}

h5 {
  font-family: "Roboto Light", sans-serif;
  font-size: 0.9em;
  margin: 0 !important;
}

h3 {
    font-family: "Playfair Display", serif;
    font-size: 1.5em;
}

.list-item{
    padding: 1em 0!important;
    opacity: 100%;
}

// .list-item{
//     opacity: 90%;
//     cursor: pointer;
// }

.list-button{
  padding-left: 1em;
  opacity: 60%;
  transition: all 0.2s ease;
}

.list-button:hover {
  cursor: pointer;
  opacity: 90%;
}


.button-icon{
    height: 0.7em;
    width: auto;
    padding: 0 0.3em
}

.padding{
  padding: 0.25em
}

</style>
