///////////////////////////////////// General

import firebase from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyBmUKxNJDUfs5E6O5YTg4fmZu6JrUTe8lw",
  authDomain: "elisabeth-unger-website.firebaseapp.com",
  databaseURL: "https://elisabeth-unger-website.firebaseio.com",
  projectId: "elisabeth-unger-website",
  storageBucket: "elisabeth-unger-website.appspot.com",
  messagingSenderId: "390355595196",
  appId: "1:390355595196:web:68b2fe4d9234807f0485b9",
  measurementId: "G-KN971V2EFL"
};

firebase.initializeApp(firebaseConfig);


///////////////////////////////////// Project Data
require('firebase/firestore')
const projectdatabase = firebase.firestore().collection('projects')
export const projectdb = projectdatabase



///////////////////////////////////// Media Database
require('firebase/storage')
const mediadatabase = firebase.storage()
export const mediadb = mediadatabase.ref()

///////////////////////////////////// Analytics
require('firebase/analytics');
firebase.analytics();


///////////////////////////////////// Authentication
require('firebase/auth')
export const auth = firebase.auth()