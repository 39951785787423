import Vue from 'vue'
import Vuex from 'vuex'

// import { Project } from '../models/project'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        projectsdb: [],
        navlinks: [
            {name: 'Projects', route: '/projects'},
            {name: 'About', route: '/about'},
            {name: 'Contact', route: '/contact'},
            {name: 'Login', route: '/login'},
        ]
    }, 
    mutations: {
        // ADD_PROJECT(state, project: Project){
        //     state.projectsdb.push(project)
        //     // Send snapshot to firebase

        // }
    }, 
    actions:{

    }
  })    