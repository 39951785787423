<template>
  <div>
    <img src="../assets/ElisabethUngerLogo.svg" alt="Elisabeth Unger">
    <p> Lanscape Architecture Student </p>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
  max-width: 200px;
  padding-bottom: 10px;
}

p {
  font-family: 'Yantramanav', sans-serif;
  font-size: 0.9em;
  opacity: 50%;
}

</style>