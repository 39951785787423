<template>
  <div class="vw-100 vh-100">
    <div class="d-flex">
      <div class="project-text col-3 padded">
          <div style="text-align: left !important">
                <img class="backarrow" v-on:click="$router.go(-1)" src="../assets/elements/back_black.svg">
          </div>
        <div v-if="projectsloaded">
            <div class="title-block">
                <h1 class="project-title">{{ project.title }}</h1>
                <h5 class="project-location">{{ project.location }}</h5>
            </div>
            <div>
                <p class="project-image-caption">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>
        </div>
      </div>

      <div class="col-9 padded">
        <b-carousel controls indicators>
          <b-carousel-slide
            v-for="image in images"
            :key="image.name"
            :img-src="image.url"
          >
            <template #img>
              <img
                class="d-block img-fluid"
                :src="image.url"
                :alt="image.name"
              />
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </div>
  </div>
</template>

<script>

import { projectdb, mediadb } from "../fire";
import { arrayops } from "../helpers";

export default {
  data() {
    return {
      projectsloaded: false,
      imagesloaded: false,
      project: Object,
      images: Object,
      activeImage: 0,
    };
  },

  methods: {
    nextImage() {
      this.activeImage = arrayops.next(this.activeImage, this.images.length);
    },
    prevImage() {
      this.activeImage = arrayops.prev(this.activeImage, this.images.length);
    },
  },

  beforeCreate: async function () {
    // Fetch project data
    var fetchedproject;
    var projectid = this.$route.params.id;
    await projectdb
      .doc(projectid)
      .get()
      .then(function (doc) {
        fetchedproject = doc.data();
      });

    this.project = fetchedproject;
    this.projectsloaded = true;

    // Fetch Project images
    var allimages = [];

    const projectref = mediadb.child(this.$route.params.id);
    await projectref.listAll().then(function (res) {
      res.items.forEach(async function (itemRef) {
        var tempImage = Object();
        tempImage["name"] = itemRef.name;
        tempImage["url"] = await itemRef.getDownloadURL();
        allimages.push(tempImage);
        console.log(tempImage);
      });
    });

    this.images = allimages;
    console.log;
    this.imagesloaded = true;
  },

  components: {
  },
};
</script>

<style scoped>

.project-text{
    min-width: 500px;
}

img {
  object-fit: cover;
  width: 100%;
  max-height: calc(100vh - 80px);
}

.padded {
  padding: 40px;
}


/* Back Button */

.backarrow {
  width: 50px;
  height: auto;
  opacity: 30%;
  transition: 0.3s;
}

.backarrow:hover {
    opacity: 60%;
}

/* ANIMATIONS */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

@keyframes opacityIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}


.title-block {
    padding: 20px 0px ;
}

.project-title {
    font-family: 'Bodoni 72', serif;
    font-size: 4em;
    color: #505050;
  animation: fadeIn 1s ease-out 0s 1;
}

.project-location {
    font-size: 1em;
    color: #afafaf;
  animation: fadeIn 2s ease-out 0s 1;
}

.project-image-caption {
    font-family: 'Yantramanav', sans-serif;
    font-size: 0.9em;
    color: #505050;
    animation: fadeIn 3s ease-out 0s 1;
}
</style>