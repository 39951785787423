<template>
  <div class="d-flex h-100 align-items-start" :class="mobile ? 'w-100 mobile' : 'flex-column desktop'">
    
    <div :class="mobile ? 'w-50' : 'w-100 h-100'">
      <LogoTitle class="mb-auto p im-fit" />
    </div>
    
    <div v-if="!mobile" class="w-100">
      <hr/>
    </div>

    <div class="d-flex flex-column" :class="mobile ? 'w-50 h-100 justify-content-center align-items-end':''">
      <router-link v-for="(navlink, index) in navlinks" :key="index" class="d-flex navlink" :to="navlink.route">{{navlink.name}}</router-link>
    </div>
  </div>
</template>


<script>
import LogoTitle from "./LogoTitle.vue";
export default {
  props: {
    mobile: Boolean
  },
  computed: {
    navlinks() {
      return this.$store.state.navlinks
    }
  },
  components: {
    LogoTitle
  }
}
</script>


<style scoped>
h1 {
  font-family: 'Playfair-Display';
  color: #505050;
}

h5 {
  color: #acacac
}

.navlink {
  font-family: 'Yantramanav', sans-serif;
  font-size: 0.9em;
  color: #acacac;
  padding: 0.1em 0
}

.mobile {
  padding: 1em;
}

.desktop {
  padding: 2em;
}

</style>
