import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Contact from '@/views/Contact.vue'
import Projects from '@/views/Projects.vue'
import SingleProjectView from '@/views/SingleProjectView.vue'
import AdminPanel from '@/views/AdminPanel.vue'
import Login from '@/views/Login.vue'

import firebase from "firebase/app";
import "firebase/auth";


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    component: Contact
  },
  {
    path: '/about',
    component: About
  },
  {
    path: '/projects/:id',
    name: 'Project Redirect', 
    component: SingleProjectView
  },
  {
    path: '/projects',
    component: Projects
  },
  {
    path: '/admin',
    name: "adminpanel",
    component: AdminPanel, 
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: "login",
    component: Login
  },
  {
    path: '*',
    redirect: { name: 'Home'},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;
  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;