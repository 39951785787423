<template>
  <div class="d-flex flex-column w-100 add-project-body">
    <div class="d-flex flex-grow-1">
      <div class="d-flex flex-column w-50 h-100 padded">
          <div class="d-flex" style="padding-bottom: 1em">
            <form @submit.prevent="pressed" class="w-100">
            
                <div class="d-flex w-100">
                    <input
                    type="text"
                    class="d-flex w-100 title form-input"
                    :class="{invalidinput: !project.title}"
                    placeholder="Project Title"
                    onfocus="this.placeholder = 'Project Title'"
                    onblur="this.placeholder = 'Project Title'"
                    v-model="project.title"
                    />
                </div>
                <div class="d-flex w-100">
                    <input
                    type="text"
                    class="d-flex location form-input w-100"
                    :class="{invalidinput: !project.location}"
                    placeholder="Location"
                    onfocus="this.placeholder = 'Location"
                    onblur="this.placeholder = 'Location'"
                    v-model="project.location"
                    />
                </div>
                </form>
            </div>
          <div class="d-flex align-items-stretch h-100 thin-border padded">
             <form @submit.prevent="pressed" class="w-100 h-100">
            <textarea
              type="text"
              class="d-flex w-100 h-100 description form-input"
              :class="{invalidinput: !project.paragraphs[currentParagraph].description}"
              placeholder="Description"
              onfocus="this.placeholder = 'Description"
              onblur="this.placeholder = 'Description'"
              v-model="project.paragraphs[currentParagraph].description"
            />
             </form>
          </div>
        
      </div>
      <div class="d-flex w-50 h-100 padded">

  
          <div v-if="!project.photos.length" v-on:click="uploadPhotos" class="d-flex justify-content-center align-content-center flex-wrap w-100 h-100 photo-upload">

              <div style="d-flex flex-column justify-content-center align-text-center h-25 v-25" >
                  <img class="d-flex" src="../assets/elements/upload_icon_black.svg" style="width: 100%; max-height: 3em">
                  <h5 class="align-text-center">Upload Images </h5>
                </div>


          </div>
      </div>
    </div>
    <div class="d-flex justify-content-center text-align-center w-100">
      <h3 v-on:click="uploadProject">Save</h3>
    </div>
  </div>
</template>

<script>

import { projectdb } from "../fire";

export default {
  data() {
    return {
    currentParagraph: 0,
      project: {
        title: "",
        location: "",
        paragraphs: [
          {
            image_name: "",
            description: "",
          },
        ],
        photos: [

        ]
      },
    };
  },
  methods: {
    prepareID(name) {
      // Check if ID exists in firestore database, if it does add a 2 behind
      return name.replace(/\s/g, '')
    },

    async uploadProject() {
      console.log(this.project.title)
      await projectdb.doc(this.prepareID(this.project.title)).set(this.project)
      .then(function() {
          console.log("Project added to database");
          alert("Project Succesfully Saved")
          
      })
      .catch(function(error) {
          console.error("Error writing project to database: ", error);
      })
      
      this.$emit('saved')
      ;
    },
    uploadPhotos() {
      alert("Upload Photos Dialogue Box")
    }
  }, 

};
</script>

<style lang="scss" scoped>

h5{
  text-align: center;
}

.add-project-body {
  --even-lighter-gray: #F2F2F2;
  --light-gray: #a2a2a2;
  --dark-gray: #404040;
}

.title {
  font-family: "Playfair Display", serif;
  font-size: 2em;
  opacity: 60%;
}

.location {
  font-family: "Roboto", sans-serif;
  font-size: 0.9em;
  opacity: 50%;
}

.description {
    font-family: "Roboto", sans-serif;
  font-size: 0.9em;
  opacity: 50%;
}

.form-input {
  border: none;
}

.form-input-div {
  padding-bottom: 0.2em;
}

input {
    opacity: 30%;
}

input:focus,
textarea:focus,
select:focus{
  outline: none;
}

.invalidinput {
    opacity: 30%;
}

// input:valid {
//   color: var(--dark-gray);
//   border-color: var(--dark-gray);
// }

// Image Input

.photo-upload {
  opacity: 30%;
  background: var(--light-gray);
  transition: all 0.2s ease;
}

.photo-upload:hover {
  cursor: pointer;
  opacity: 40%
}

.padded {
    padding: 1em;
}

.thin-border{
    border-width: thin;
    border-style: solid;
    border-color: var(--light-gray);
}



</style>